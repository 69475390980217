<template>
  <MainHeader>
    <div class="header-row">
      <div class="title-section">
        <div class="sidebar-btn" @click="$emit('openSidebar')"></div>
        <div class="title-container">
          <div class="title">Members</div>
          <div class="current-date">{{ getCurrentDate() }}</div>
        </div>
      </div>
      <div class="header-button-section">
        <ActionButton
          :width="'110px'"
          :height="'44px'"
          :text="'Import'"
          @click="isImportModalShown = true"
        />
        <ImportModal
          :isModalShown="isImportModalShown"
          :isModalLoading="isModalLoading"
          :importedFileName="csvFile?.name"
          :modalMessage="modalMessage"
          :modalError="modalError"
          @setCsvFile="setCsvFile"
          @importItems="submitMembersImport"
          @hideModal="closeImportModal"
        />
        <ActionButton
          :width="'110px'"
          :height="'44px'"
          :text="'Export'"
          @click="exportMembersToFile"
        />
        <ExportModal
          :isModalShown="isExportModalShown"
          :isModalLoading="isModalLoading"
          :modalMessage="modalMessage"
          :modalError="modalError"
          @hideModal="closeExportModal"
        />
        <ActionButton
          :width="'140px'"
          :height="'44px'"
          :text="'Create Account'"
          @click="showModalCreateMembers"
        />
        <CreateAccountActionModal
          :isModalShown="isModalShown"
          :newClientName="newClientName"
          :newClientEmail="newClientEmail"
          :newMemberCompany="newMemberCompany"
          :newMemberAddress="newMemberAddress"
          :newClientPhone="newClientPhone"
          :newClientPassword="newClientPassword"
          :confirmNewPassword="confirmNewPassword"
          :isModalLoading="isModalLoading"
          :modalError="modalError"
          @setNewClientName="setNewClientName"
          @setNewClientEmail="setNewClientEmail"
          @setNewMemberCompany="setNewMemberCompany"
          @setNewMemberAddress="setNewMemberAddress"
          @setNewClientPhone="setNewClientPhone"
          @setNewClientPassword="setNewClientPassword"
          @setConfirmNewPassword="setConfirmNewPassword"
          @createAccount="handleMemberCreation"
          @hideModal="hideModal"
        />
        <ActionButton
          :width="'105px'"
          :height="'44px'"
          :text="'Invite'"
          :class="{ 'invite-action-btn': true }"
          @click="showModalInvite"
        />
        <InviteActionModal
          :isModalShown="isInviteModalShown"
          :isModalLoading="isModalLoading"
          :newInviteEmail="newEmailInvite"
          :modalError="modalError"
          @setNewEmailInvite="setEmailInvite"
          @inviteMembers="InviteMembers"
          @hideModal="hideInviteModal"
        />
        <RenewSubcsriptionActionModal
          :isModalShown="isModalSubcriptionShown"
          :isModalLoading="isModalLoading"
          :modalError="modalError"
          @hideModal="hideSubscriptionModal"
        />
        <DeleteActionModal
          :isModalShown="isModalDeleteMembersShown"
          :isModalLoading="isModalLoading"
          :modalError="modalError"
          @deleteMember="deleteMember()"
          @hideModal="hideDeleteMembersModal"
        />
        <GrantedActionModal
          :isModalShown="isCompleteGrantedModalShown"
          :isModalLoad="isModalLoading"
          :modalError="modalError"
          :grantedUser="userActionName"
          @agreeGranted="isCompleteGrantedModalShown = false"
          @hideModal="isCompleteGrantedModalShown = false"
        />
        <GrantedConfigActionModal
          :isModalShown="isModalGrantedShown"
          :memberId="grantedMemberId"
          :currentMemberPeriodEnd="currentMemberPeriodEnd"
          @completeGranted="isCompleteGrantedModalShown = true"
          @hideModal="isModalGrantedShown = false"
        />
        <LookInModal
          :isModalShown="isModalLookInShown"
          :memberId="memberLookInId"
          @hideModal="isModalLookInShown = false"
        />
        <ManageUsersModal
          :isModalShown="isManageUsersModalShown"
          :memberId="memberLookInId"
          :isModalLoading="false"
          @hideModal="isManageUsersModalShown = false"
        />
        <StopSubscriptionActionModal
          :isModalShown="isModalStoppedShown"
          :isModalLoad="isModalLoading"
          :modalError="modalError"
          :stoppedUser="userActionName"
          @agreeStopped="hideStoppedModal"
          @hideModal="hideStoppedModal"
        />
        <EditMemberActionModal
          :isModalShown="isMemberEditModalShown"
          :memberToEdit="memberToEdit"
          @hideModal="hideEditMemberActionModal"
        />
      </div>
    </div>
    <div class="header-row">
      <div class="left-side-actions">
        <SearchField
          @search="handleSearch($event)"
          @cancelSearch="handleSearch($event)"
        />
        <!--        <MoreActionsBtn-->
        <!--          :width="'44px'"-->
        <!--          :height="'44px'"-->
        <!--          :class="{'active-more-action-btn': actionActive}"-->
        <!--          :isActive="isActionMenuShown"-->
        <!--          @click="openHeaderActionMenu"-->
        <!--        />-->
        <!--        <ActionMenu-->
        <!--          :width="'280px'"-->
        <!--          :height="'47px'"-->
        <!--          :top="'50px'"-->
        <!--          :isActionMenuShown="isActionMenuShown"-->
        <!--          @closeActionMenu="closeHeaderActionMenu"-->
        <!--        >-->
        <!--          <ActionMenuItem-->
        <!--            v-for="(action, index) in Object.values(menuActions)"-->
        <!--            :key="index"-->
        <!--            :text="action"-->
        <!--            @actionMenuItemClick="handleActionMenuClick(action)"-->
        <!--          />-->
        <!--        </ActionMenu>-->
      </div>
    </div>
  </MainHeader>
  <MainCard>
    <CardTableHeader
      :isExpirationDateShown="true"
      :isMembersStatusShown="true"
      :isMemberLastLoginShown="true"
      :isNameSortArrowHiddent="true"
      :isMemberRoleShown="true"
      :order="order"
      :selectedRows="selectedRows"
      @checkboxSelect="selectAllRows"
      @removeSelection="removeRowsSelection"
    />
    <DividerLine />
    <div
      class="members-list"
      v-if="currentPage.length && !errorMessage && !isLoading"
    >
      <div class="action-btn-absolute-wrapper">
        <div
          v-for="page in currentPage"
          :key="currentPage"
          class="action-btn-container"
        >
          <MoreActionsBtn
            :width="'32px'"
            :height="'32px'"
            :isActive="openedMenu === page.id"
            @click="switchActionMenuState(page.id)"
          />
          <ActionMenu
            :width="'282px'"
            :height="'fit-content'"
            :top="'40px'"
            :right="'20px'"
            :isActionMenuShown="openedMenu === page.id"
            @closeActionMenu="closeActionMenu"
          >
            <ActionMenuItem
              v-for="(action, index) in menuActions"
              :key="index"
              :text="action"
              @actionMenuItemClick="
                handleActionMenuClick(
                  $event,
                  page.full_name,
                  page.id,
                  page.subscription.current_period_end
                )
              "
            />
          </ActionMenu>
        </div>
      </div>

      <div style="overflow-x: scroll; padding-bottom: 4px">
        <MembersCardRow
          v-for="mem in currentPage"
          :key="mem.id"
          :id="mem.id"
          :name="mem.full_name"
          :status="mem.subscription.status"
          :lastLoginDate="mem.last_login_date"
          :expirationDate="mem.subscription.current_period_end"
          :company="mem.represented_company"
          :selectedRows="selectedRows"
          :menuActions="menuActions"
          :actionMenuWidth="'282px'"
          :actionMenuTop="'45px'"
          :isCheckboxShown="false"
          :role="mem.role"
          @checkboxSelect="selectOneRow(mem.id)"
          @removeSelection="removeOneRowSelection(mem.id)"
          @actionMenuItemClick="
            handleActionMenuClick(
              $event,
              mem.full_name,
              mem.id,
              mem.subscription.current_period_end
            )
          "
        />
      </div>
    </div>
    <div class="error-message" v-if="errorMessage">
      {{ errorMessage }}
    </div>
    <Spinner v-if="isLoading" />
    <DividerLine />
    <CardFooter
      :currentPageNumber="currentPageNumber"
      :totalPages="toPages"
      :currentItemsCount="`${fromItems}-${toItems}`"
      :totalItemsCount="totalItemsCount"
      :RowsPerPage="RowsPerPage"
      @setCurrentPerPage="setCurrentPerPage($event)"
      @showPrevPage="getPrevPage"
      @showNextPage="getNextPage"
    />
  </MainCard>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import MainHeader from '../../common/header/MainHeader.vue'
import MainCard from '../../common/mainContent/MainCard.vue'
import CardTableHeader from '../../common/mainContent/CardTableHeader.vue'
import DividerLine from '../../common/DividerLine.vue'
import CardFooter from '../../common/mainContent/CardFooter.vue'
import UndoRedoBtns from '../../common/header/UndoRedoBtns.vue'
import ActionMenu from '../../common/actionMenu/ActionMenu.vue'
import ActionMenuItem from '../../common/actionMenu/ActionMenuItem.vue'
import Spinner from '../../common/Spinner.vue'
import ActionButton from '../../common/ActionButton.vue'
import SearchField from '../../common/header/SearchField.vue'
import MoreActionsBtn from '../../common/MoreActionsBtn.vue'
import CreateAccountActionModal from './actionModals/CreateAccountActionModal.vue'
import EditMemberActionModal from './actionModals/EditMemberActionModal.vue'
import RenewSubcsriptionActionModal from './actionModals/RenewSubscriptionActionModal.vue'
import InviteActionModal from './actionModals/InviteActionModal.vue'
import DeleteActionModal from './actionModals/DeleteActionModal.vue'
import MembersCardRow from './MembersCardRow.vue'
import GrantedActionModal from './actionModals/GrantedActionModal.vue'
import StopSubscriptionActionModal from './actionModals/StopSubscriptionActionModal.vue'
import ImportModal from '../../common/ImportModal.vue'
import ExportModal from '../../common/ExportModal.vue'

import { UserSubscriptionStatus } from '../../../utils/constants'
import {
  getCurrentDate,
  convertUTCDateTimeToLocal,
} from '../../../utils/helpers'
import { exportMembers } from '../../../api/usersApi'
import LookInModal from '@/components/admin/LookInModal.vue'
import ManageUsersModal from '@/components/admin/ManageUsersModal.vue'

import GrantedConfigActionModal from '@/components/admin/members/actionModals/GrantedConfigActionModal.vue'

export default {
  components: {
    GrantedConfigActionModal,
    LookInModal,
    ManageUsersModal,
    MainHeader,
    MainCard,
    CardTableHeader,
    DividerLine,
    CardFooter,
    UndoRedoBtns,
    ActionMenu,
    ActionMenuItem,
    Spinner,
    ActionButton,
    SearchField,
    MoreActionsBtn,
    CreateAccountActionModal,
    EditMemberActionModal,
    RenewSubcsriptionActionModal,
    InviteActionModal,
    DeleteActionModal,
    MembersCardRow,
    GrantedActionModal,
    StopSubscriptionActionModal,
    ImportModal,
    ExportModal,
  },
  emits: ['openSidebar'],
  data() {
    return {
      newSortType: 'All',
      isLoading: false,
      pageSize: 10,
      pageSize: 'All',
      currentPageNumber: 1,
      selectedRows: [],
      order: '',
      isModalShown: false,
      isModalLoading: false,
      isModalGrantedShown: false,
      isModalLookInShown: false,
      isManageUsersModalShown: false,
      isCompleteGrantedModalShown: false,
      isModalStoppedShown: false,
      modalMessage: '',
      modalError: '',
      memberLookInId: '',
      grantedMemberId: '',
      errorMessage: '',
      currentMemberPeriodEnd: '',
      newEmailInvite: '',
      newClientName: '',
      newClientEmail: '',
      newMemberCompany: '',
      newMemberAddress: '',
      newClientPhone: '',
      membersIds: [],
      newClientPassword: '',
      confirmNewPassword: '',
      searchTerm: '',
      userActionName: '',
      actionActive: false,
      isActionMenuShown: false,
      isSortDropdownOpened: false,
      isInviteModalShown: false,
      isModalSubcriptionShown: false,
      isModalDeleteMembersShown: false,
      isMemberEditModalShown: false,
      isImportModalShown: false,
      isExportModalShown: false,
      memberToEdit: {},
      menuActions: {
        EDIT_MEMBER: 'Edit',
        GRANT_SUBSCRIPTION: 'Grant subscription',
        EDIT_SUBSCRIPTION: 'Edit subscription',
        STOP_SUBSCRIPTION: 'Stop subscription',
        LOOK_IN: 'Look in',
        DELETE: 'Delete',
        ASSIGN_SALES_ROLE: 'Assign Sales Manager role',
        UNASSIGN_SALES_ROLE: 'Unassign Sales Manager role',
        MANAGE_USERS: 'Manage Users',
      },
      RowsPerPage: ['All', 10, 20, 50],
      csvFile: null,
      UserSubscriptionStatus,
      openedMenu: '',
    }
  },
  async created() {
    await this.getFirstPage()
  },
  computed: {
    ...mapState(['users']),
    membersList() {
      return this.users.membersList
    },
    currentPage() {
      if (this.pageSize === 'All') return this.membersList

      const start =
        this.currentPageNumber === 1
          ? 0
          : this.currentPageNumber * this.pageSize - this.pageSize

      const stop =
        this.currentPageNumber === 1
          ? this.pageSize
          : this.currentPageNumber * this.pageSize

      const page = this.membersList.slice(start, stop)

      return page
    },
    totalItemsCount() {
      return this.users.membersCount
    },
    lastId() {
      return this.currentPage.length ? this.currentPage.pop().id : 0
    },
    pageItemsBase() {
      return this.pageSize * this.currentPageNumber
    },
    fromItems() {
      if (this.pageSize === 'All') return 1

      return this.totalItemsCount === 0
        ? 0
        : this.pageItemsBase + 1 - this.pageSize
    },
    toItems() {
      if (this.pageSize === 'All') return this.membersList.length

      return this.pageItemsBase < this.totalItemsCount
        ? this.pageItemsBase
        : this.totalItemsCount
    },
    toPages() {
      if (this.pageSize === 'All') return 1
      if (this.totalItemsCount < this.pageSize) return 1

      return Math.ceil(this.totalItemsCount / this.pageSize)
    },
  },
  watch: {
    selectedRows(newValue, oldValue) {
      if (newValue.length >= 1) {
        this.actionActive = true
      } else {
        this.actionActive = false
      }
    },
  },
  methods: {
    ...mapActions([
      'getNextMembersPage',
      'InviteUser',
      'createClientAccount',
      'deleteUsers',
      'searchUsers',
      'grantedSubscription',
      'revokeSubscription',
      'clearMembers',
      'importMembers',
      'assignSalesManagerRole',
      'unassignSalesManagerRole',
    ]),
    convertUTCDateTimeToLocal,
    switchActionMenuState(id) {
      if (this.openedMenu === id) this.openedMenu = null
      else this.openedMenu = id
    },
    closeActionMenu() {
      this.openedMenu = null
    },
    handleActionMenuItemClick(action, id, subjectId) {
      this.handleActionMenuClick(action, id, subjectId)
    },
    handleGeneralError(error) {
      console.log('==== error while obtaining pipeline info: ', error)
      this.errorMessage = 'Something went wrong, please try again'
    },
    async getFirstPage() {
      this.currentPageNumber = 1

      try {
        await this.getNextMembersPage({
          pageSize: this.pageSize,
          pageNumber: this.currentPageNumber,
          searchTerm: this.searchTerm,
        })
      } catch (error) {
        this.errorMessage = 'Something went wrong...'
      } finally {
        this.isLoading = false
      }
    },
    async setCurrentPerPage(rowperPage) {
      this.currentPageNumber = 1
      this.pageSize = rowperPage

      await this.clearMembers()
      await this.getFirstPage()
    },
    async handleSearch(searchValue) {
      this.isLoading = true
      this.searchTerm = searchValue

      try {
        await this.clearMembers()
        await this.getFirstPage()
      } catch (error) {
        this.handleGeneralError(error)
      } finally {
        this.isLoading = false
      }
    },
    async getNextPage() {
      if (
        (this.currentPageNumber !== 0 &&
          this.toItems === this.totalItemsCount) ||
        this.isLoading
      )
        return

      this.currentPageNumber += 1
      this.selectedRows = []

      if (this.currentPage.length) return

      this.isLoading = true

      try {
        const payload = {
          order: this.order,
          pageSize: this.pageSize,
          searchTerm: this.searchTerm,
          pageNumber: this.currentPageNumber,
        }
        await this.getNextMembersPage(payload)
      } catch (error) {
        this.errorMessage = 'Something went wrong...'
      } finally {
        this.isLoading = false
      }
    },
    async deleteMember() {
      try {
        await this.deleteUsers(this.membersIds)
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
        this.isModalDeleteMembersShown = false
      }
    },
    async assignSalesRole() {
      try {
        await this.assignSalesManagerRole(this.membersIds[0])
      } catch (error) {
        this.handleGeneralError(error)

        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
    async unassignSalesRole() {
      try {
        await this.unassignSalesManagerRole(this.membersIds[0])
      } catch (error) {
        this.handleGeneralError(error)

        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
    getPrevPage() {
      if (this.currentPageNumber === 1) return

      this.selectedRows = []
      this.currentPageNumber -= 1
    },
    hideDeleteMembersModal() {
      this.isModalDeleteMembersShown = false
    },
    hideGrantedModal() {
      this.isModalGrantedShown = false
    },
    hideSubscriptionModal() {
      this.isModalSubcriptionShown = false
    },
    setEmailInvite(newEmailInvite) {
      this.newEmailInvite = newEmailInvite
    },
    setNewClientName(newClientName) {
      this.newClientName = newClientName
    },
    setNewClientEmail(newClientEmail) {
      this.newClientEmail = newClientEmail
    },
    setNewMemberCompany(newMemberCompany) {
      this.newMemberCompany = newMemberCompany
    },
    setNewMemberAddress(newMemberAddress) {
      this.newMemberAddress = newMemberAddress
    },
    setNewClientPhone(newClientPhone) {
      this.newClientPhone = newClientPhone
    },
    setNewClientPassword(newClientPassword) {
      this.newClientPassword = newClientPassword
    },
    setConfirmNewPassword(confirmNewPassword) {
      this.confirmNewPassword = confirmNewPassword
    },
    validateEmail() {
      this.newClientEmail = this.newClientEmail.replaceAll(' ', '')

      const emailPattern = new RegExp(/^.+@.+\..+$/)

      return emailPattern.test(this.newClientEmail)
    },
    async handleMemberCreation() {
      if (!this.newClientName || !this.newClientEmail) {
        this.modalError = 'Client name or email are required'
        return
      }

      if (!this.validateEmail()) {
        this.modalError = 'Email is not valid'
        return
      }

      if (!this.newClientPassword || this.newClientPassword.length < 8) {
        this.modalError = 'Please enter password'
        return
      }

      if (this.newClientPassword !== this.confirmNewPassword) {
        this.modalError = 'Please confirm new password'
        return
      }

      const payload = {
        new_member: {
          email: this.newClientEmail,
          represented_company: this.newMemberCompany,
          address: this.newMemberAddress,
          phone: this.newClientPhone,
          full_name: this.newClientName,
          password: this.newClientPassword,
        },
      }

      try {
        this.isModalLoading = true
        await this.createClientAccount(payload)
        this.isModalShown = false
      } catch (error) {
        console.log('==== error while creating users: ', error)
        this.modalError = 'Something went wrong, please try again...'
      } finally {
        this.isModalLoading = false
        this.newClientName = ''
        this.newClientPhone = ''
        this.newClientEmail = ''
        this.confirmNewPassword = ''
        this.newClientPassword = ''
        this.newMemberCompany = ''
        this.newMemberAddress = ''
        this.modalError = ''
      }
    },
    async InviteMembers() {
      if (!this.newEmailInvite) {
        this.modalError = 'Email is required'
        return
      }
      try {
        this.isModalLoading = true
        await this.InviteUser(this.newEmailInvite)
        this.isModalLoading = false
      } catch (error) {
        this.modalError = 'Something went wrong, please try again...'
      } finally {
        this.newEmailInvite = ''
      }
    },
    hideInviteModal() {
      this.modalError = ''
      this.newClientName = ''
      this.newClientPhone = ''
      this.newClientEmail = ''
      this.confirmNewPassword = ''
      this.newClientPassword = ''
      this.newMemberCompany = ''
      this.newMemberAddress = ''
      this.modalError = ''
      this.isInviteModalShown = false
    },
    hideStoppedModal() {
      this.isModalStoppedShown = false
    },
    hideModal() {
      this.modalError = ''
      this.newClientName = ''
      this.newClientPhone = ''
      this.newClientEmail = ''
      this.confirmNewPassword = ''
      this.newClientPassword = ''
      this.newMemberCompany = ''
      this.newMemberAddress = ''
      this.modalError = ''
      this.isModalShown = false
    },
    showModalInvite() {
      this.isInviteModalShown = true
    },
    showModalCreateMembers() {
      this.isModalShown = true
    },
    hideEditMemberActionModal() {
      this.isMemberEditModalShown = false
      this.memberToEdit = {}
    },
    setCsvFile(evt) {
      this.csvFile = evt.target.files[0]
    },
    closeImportModal() {
      this.isImportModalShown = false
      this.isModalLoading = false
      this.modalMessage = ''
      this.modalError = ''
      this.csvFile = null
    },
    async submitMembersImport() {
      this.modalError = ''
      this.modalMessage = ''
      this.isModalLoading = true

      try {
        const message = await this.importMembers(this.csvFile)
        await this.clearMembers()
        await this.getFirstPage()

        this.modalMessage = message
      } catch (error) {
        this.modalError = 'Something went wrong. Please, try again'
      } finally {
        this.isModalLoading = false
        this.csvFile = null
      }
    },
    async exportMembersToFile() {
      this.isExportModalShown = true
      this.isModalLoading = true

      try {
        // For now, this will return list of members
        const membersList = await exportMembers()

        const membersCsvData = [
          'name,company,email,phone,address,last login date,subscription status',
        ]

        membersList.forEach((m) => {
          const name = m.full_name || ''
          const representedCompany = m.represented_company || ''
          const phone = m.phone || ''
          const address = m.address || ''
          const lastLogin =
            this.convertUTCDateTimeToLocal(m.last_login_date) || ''
          const subscriptionStatus = Object.keys(this.UserSubscriptionStatus)[
            m.subscription.status
          ]
          const csvData = `${name},${representedCompany},${m.email},${phone},${address},${lastLogin},${subscriptionStatus}`

          membersCsvData.push(csvData)
        })

        const csvString = membersCsvData.join('\n')
        const membersCsv = new Blob([csvString], { type: 'text/csv' })

        const a = document.createElement('a')

        a.href = URL.createObjectURL(membersCsv)
        a.download = 'members_export.csv'
        a.click()

        URL.revokeObjectURL(a.href)
        a.remove()

        this.modalMessage = 'Successfully exported members'
      } catch (error) {
        this.modalError = 'Something went wrong. Please, try again'
      } finally {
        this.isModalLoading = false
      }
    },
    closeExportModal() {
      this.isExportModalShown = false
      this.isModalLoading = false
      this.modalMessage = ''
      this.modalError = ''
    },
    openHeaderActionMenu() {
      this.isActionMenuShown = true
      this.actionActive = false
    },
    closeHeaderActionMenu() {
      this.isActionMenuShown = false
    },
    openSortDropdown() {
      this.isSortDropdownOpened = true
    },
    closeSortDropdown() {
      this.isSortDropdownOpened = false
    },
    selectAllRows() {
      const ids = this.currentPage.map((item) => item.id)
      this.selectedRows = ids
    },
    removeRowsSelection() {
      this.selectedRows = []
    },
    selectOneRow(rowId) {
      this.selectedRows.push(rowId)
      if (this.selectedRows.length >= 1) {
        this.actionActive = true
      }
    },
    removeOneRowSelection(rowId) {
      const rowIndex = this.selectedRows.indexOf(rowId)
      this.selectedRows.splice(rowIndex, 1)
      if (this.selectedRows.length < 1) {
        this.actionActive = false
      }
    },
    setNewSortType(sortName) {
      this.isSortDropdownOpened = false
      this.newSortType = sortName
    },
    async handleActionMenuClick(
      action,
      userActionName,
      memberId = null,
      memberPeriodEnd
    ) {
      this.isActionMenuShown = false
      this.isLoading = true
      this.userActionName = userActionName
      this.membersIds = memberId ? [memberId] : this.selectedRows

      if (!this.membersIds.length) {
        this.isLoading = false
        return
      }

      try {
        if (action === this.menuActions.EDIT_MEMBER) {
          this.isMemberEditModalShown = true
          this.memberToEdit = this.currentPage.find((i) => i.id === memberId)
        } else if (action === this.menuActions.EDIT_SUBSCRIPTION) {
          this.grantedMemberId = memberId
          this.currentMemberPeriodEnd = memberPeriodEnd
          this.isModalGrantedShown = true
        } else if (action === this.menuActions.DELETE) {
          this.isModalDeleteMembersShown = true
        } else if (action === this.menuActions.GRANT_SUBSCRIPTION) {
          this.grantedMemberId = memberId
          this.isModalGrantedShown = true
        } else if (action === this.menuActions.STOP_SUBSCRIPTION) {
          await this.revokeSubscription(memberId)
          this.isModalStoppedShown = true
        } else if (action === this.menuActions.LOOK_IN) {
          this.isModalLookInShown = true
          this.memberLookInId = memberId
        } else if (action === this.menuActions.ASSIGN_SALES_ROLE) {
          this.assignSalesRole()
        } else if (action === this.menuActions.UNASSIGN_SALES_ROLE) {
          this.unassignSalesRole()
        } else if (action === this.menuActions.MANAGE_USERS) {
          this.isManageUsersModalShown = true
          this.memberLookInId = memberId
        }
      } catch (error) {
        console.log('error', error)
        this.handleGeneralError(error)
      } finally {
        this.isLoading = false
      }
    },
    getCurrentDate,
  },
  async beforeUnmount() {
    await this.clearMembers()
  },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styleVars.scss';

.active-more-action-btn {
  background-color: $appActionColor;
  background-image: url('../../../assets/icons/more-white-icon.svg');
}
.header-row {
  width: 100%;
  height: 50%;
  display: flex;
  margin-top: 25px;
  justify-content: space-between;
  color: $fontColorBlack;

  .sidebar-btn {
    width: 0;
    height: 0;
    position: absolute;
    background-size: 24px;
    background-repeat: no-repeat;
    background-image: url('../../../assets/icons/hamburger-icon.svg');
  }

  .title-section {
    display: flex;
    justify-content: center;
    align-items: center;

    .title-container {
      display: flex;
      flex-direction: column;

      .title {
        font-size: $fontSizeTitle;
      }

      .current-date {
        font-size: $fontSizeMinified;
        color: $fontColorGray;
      }
    }

    .vertical-divider {
      width: 1px;
      height: 54px;
      margin-left: 20px;
      top: 35px;
      background: #c9cde8;
    }
  }
  .header-button-section {
    position: relative;
    display: flex;

    .action-button {
      margin-left: 12px;
    }

    .invite-action-btn {
      background-color: #e5e5e5;
      border: 1px solid $appActionColor;
      color: $appActionColor;
    }
  }

  .left-side-actions {
    position: relative;
    display: flex;
  }

  .sort-side {
    position: relative;
    display: flex;

    .field-label {
      color: $fontColorBlack;
      font-size: $fontSizeStandart;
      margin-top: 15px !important;
    }
    .sort-wrapper {
      width: 170px;
      height: 68px;
      position: relative;
      margin-left: 15px;

      .sort-field {
        width: 170px;
        height: 44px;
        display: flex;
        align-items: center;
        padding: 0 6px;
        position: relative;
        font-size: $fontSizeStandart;
        border: 1px solid $grayBorderColor;
        box-sizing: border-box;
        background-color: white;
        border-radius: 4px;
        cursor: pointer;

        .open-dropdown-btn {
          width: 20px;
          height: 20px;
          top: 12px;
          right: 19px;
          position: absolute;
          background-position: center;
          background-size: 20px;
          background-repeat: no-repeat;
          background-image: url('../../../assets/icons/chevron-down-black-icon.svg');
        }

        .close-dropdown-btn {
          background-image: url('../../../assets/icons/chevron-up-black-icon.svg');
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .header-row {
    align-items: center;

    .sidebar-btn {
      position: relative;
      width: 24px;
      height: 24px;
      margin-right: 15px;
    }

    .action-button {
      margin-top: 0;
    }
  }
}

@media (max-width: 420px) {
  .header-button-section {
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: -2px;

    .action-button {
      width: 78px !important;
      font-size: $fontSizeActionButtonMinified;
      padding: 0 5px;
      margin-top: 2px;
    }
  }

  .header-row {
    .sort-side {
      .field-label {
        margin-top: 5px !important;
      }

      .sort-wrapper {
        height: 0;
        margin-left: 0;

        .sort-field {
          width: 85px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import '../../../assets/styleVars.scss';

.members-list {
  width: 100%;
  height: calc(100% - 104px);
  overflow-y: scroll;
  position: relative;
}

.action-btn-absolute-wrapper {
  height: 44px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
}

.action-btn-container {
  width: 44px;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  width: 64px;
  height: 100%;

  .more-actions-btn {
    border: none;
    width: 100% !important;
  }

  .more-actions-btn-active-state {
    border: 1px solid $grayBorderColor;
  }
}

.error-message {
  width: 100%;
  height: calc(100% - 104px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: $redWarningColor;
}
</style>
